export const INIT_TYPE_OPTIONS = [
  { label: "Tous les types", value: "Tous les types"},
  { label: "Algérien", value: "Algérien"},
  { label: "Allemand", value: "Allemand"},
  { label: "Américain", value: "Américain"},
  { label: "Brésilien", value: "Brésilien"},
  { label: "Brunch", value: "Brunch"},
  { label: "Burger", value: "Burger"},
  { label: "Café", value: "Café"},
  { label: "Cambodgien", value: "Cambodgien"},
  { label: "Colombien", value: "Colombien"},
  { label: "Coréen", value: "Coréen"},
  { label: "Français", value: "Français"},
  { label: "Inclassable", value: "Inclassable"},
  { label: "Irlandais", value: "Irlandais"},
  { label: "Italien", value: "Italien"},
  { label: "Japonais", value: "Japonais"},
  { label: "Jeux de société", value: "Jeux de société"},
  { label: "Méxicain", value: "Méxicain"},
  { label: "Pâtisserie", value: "Pâtisserie"},
  { label: "Péruvien", value: "Péruvien"},
  { label: "Poké", value: "Poké"},
  { label: "Scandinave", value: "Scandinave"},
  { label: "Sicilien", value: "Sicilien"},
  { label: "Thaï", value: "Thaï"},
  { label: "Vegan", value: "Vegan"},
];

export const INIT_ARR_OPTIONS = [
  { label: "Tous les arr.", value: "Tous les arr."},
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
];
